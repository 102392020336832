import { createSession, deleteSession } from '@/lib/session'
import createAxiosInstance from '@/services/api'
import { User } from '@/types/user'
import { isAxiosError } from 'axios'
import { SingleResponse } from './../types/api'
import useCurrentUser from './useCurrentUser'
import useTranslate from './useTranslate'

export enum SignInType {
  Credentials = 'credentials',
  Google = 'google'
}

export const useSignIn = () => {
  const axios = createAxiosInstance()
  const { setCurrentUser } = useCurrentUser()
  const t = useTranslate()

  const signIn = (type: SignInType, data?: any) => {
    if (type === SignInType.Credentials) {
      return new Promise<User>(async (resolve, reject) => {
        try {
          const response = await axios.post<
            SingleResponse<{ access_token: string }>
          >('/auth/login', data)

          const token = response?.data.data.access_token

          if (token) {
            const profile = await axios.get<SingleResponse<User>>('/profile', {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })

            resolve(profile.data.data)
            setCurrentUser(profile.data.data)
            createSession(profile.data.data.id, token)
          }
        } catch (error: any) {
          if (isAxiosError(error)) {
            if (error.response?.status === 401) {
              reject(t('common.wrong_email_or_password'))
            }

            reject(error.response?.data?.message)
          } else {
            reject(error.message)
          }
        }
      })
    } else if (type === SignInType.Google) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await axios.post(
            '/auth/google',
            {
              code: data?.code
            },
            {
              timeout: 3000
            }
          )
          const token = response?.data.access_token

          if (token) {
            const profile = await axios.get<SingleResponse<User>>('/profile', {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })

            resolve(profile.data.data)
            setCurrentUser(profile.data.data)
            createSession(profile.data.data.id, token)
          }
        } catch (error: any) {
          if (isAxiosError(error)) {
            if (error.response?.status === 401) {
              reject(t('common.something_went_wrong'))
            }
          }
          reject(error.message)
        }
      })
    }
  }

  const logout = () => {
    deleteSession()

    setTimeout(() => {
      setCurrentUser(null)
    }, 1000)
  }

  return { signIn, logout }
}
