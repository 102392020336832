import Button from '@/components/Button'
import Divider from '@/components/Divider'
import FormItem from '@/components/FormItem'
import Input from '@/components/Input'
import useFormValidation from '@/hooks/useFormValidation'
import { SignInType, useSignIn } from '@/hooks/useSignIn'
import useTranslate from '@/hooks/useTranslate'
import { Alert, Form } from 'antd'
import classNames from 'classnames'
import { Facebook } from 'iconsax-react'
import dynamic from 'next/dynamic'
import { PropsWithChildren, useState } from 'react'

const GoogleButton = dynamic(() => import('./GoogleButton'), {
  ssr: false
})

type FormValues = {
  email: string
  password: string
}

export type AuthFormProps = PropsWithChildren<{
  className?: string
  onPwdReset?: () => void
}>

const AuthForm = ({ className, onPwdReset }: AuthFormProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const t = useTranslate()
  const { signIn } = useSignIn()
  const onSubmit = async (data: any) => {
    setErrorMessage(undefined)
    setIsLoading(true)

    try {
      await signIn(SignInType.Credentials, data)
    } catch (error: any) {
      setErrorMessage(error)
      console.log(error)
      setIsLoading(false)
    }
  }

  const {
    rules: { required }
  } = useFormValidation()

  const handleGoogleError = (error: any) => {
    setErrorMessage(t('common.something_went_wrong'))
  }

  return (
    <div className={classNames('', className)}>
      {!!errorMessage && (
        <Alert type="error" className="!mb-5" message={t(errorMessage)} />
      )}
      <Form onFinish={onSubmit} layout="vertical">
        <FormItem name="email" label={t('common.email')} rules={[required()]}>
          <Input type="email" allowClear placeholder="info@sachemo.ge" />
        </FormItem>

        <FormItem
          name="password"
          label={t('common.password')}
          rules={[required()]}
        >
          <Input.Password
            type="password"
            placeholder="*******"
            allowClear
            addonAfter={
              <Button
                type="link"
                className="!p-0 !text-xs"
                size="small"
                htmlType="button"
                onClick={onPwdReset}
              >
                {t('common.forgot')}?
              </Button>
            }
          />
        </FormItem>

        <Button
          block
          className="mb-8 mt-6"
          type="primary"
          loading={isLoading}
          htmlType="submit"
        >
          {t('common.login')}
        </Button>

        <Divider className="m-0" />

        <div className="grid grid-cols-2 gap-3 pt-8">
          <Button
            icon={<Facebook size="16" color="#1877f2" variant="Bold" />}
            type="default"
            block
            className="!px-2"
          >
            <span className="hidden md:inline-block">
              {t('common.login_via_fb')}
            </span>
          </Button>
          <GoogleButton onError={handleGoogleError} />
        </div>
      </Form>
    </div>
  )
}

export default AuthForm
