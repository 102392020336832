import createAxiosInstance from '@/services/api'
import { AxiosRequestConfig } from 'axios'
import { useLocale } from 'next-intl'
import qs from 'query-string'
import useSWR, { SWRConfiguration } from 'swr'
import { useSignIn } from './useSignIn'

export const useFetch = <T>(
  apiURL: string,
  params: any = null,
  config: AxiosRequestConfig = {},
  swrConfig?: SWRConfiguration
) => {
  const locale = useLocale()
  const { logout } = useSignIn()
  const axios = createAxiosInstance(locale)
  const queryString = qs.stringify(params)
  const url = `${apiURL}${!!params ? `?${queryString}` : ''}`
  const fetcher = (url: string) =>
    axios
      .get(url, config)
      .then((res) => res.data)
      .catch((e) => {
        if (e?.response?.status) {
          logout()
        }
      })
  const { data, error, isLoading, mutate } = useSWR<T>(url, fetcher, {
    revalidateOnFocus: false,
    ...swrConfig
  })

  return {
    data,
    isLoading,
    isError: error,
    mutate
  }
}

export const useDashboardFetch = <T>(
  apiURL: string,
  params: any = null,
  config: AxiosRequestConfig = {},
  swrConfig?: SWRConfiguration
) => {
  const locale = 'ka'
  const axios = createAxiosInstance(locale)
  const { logout } = useSignIn()
  const queryString = new URLSearchParams(params).toString()
  const url = `${apiURL}${!!params ? `?${queryString}` : ''}`
  const fetcher = (url: string) =>
    axios
      .get(url, config)
      .then((res) => res.data)
      .catch((e) => {
        if (e?.response?.status) {
          logout()
        }
      })
  const { data, error, isLoading, mutate } = useSWR<T>(url, fetcher, {
    revalidateOnFocus: false,
    ...swrConfig
  })

  return {
    data,
    isLoading,
    isError: error,
    mutate
  }
}
