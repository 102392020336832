import { notification } from 'antd'
import { ArgsProps } from 'antd/es/notification/interface'
import Status, { ProcessStatus } from '@/components/Status'
import { CloseCircle } from 'iconsax-react'

notification.config({
  top: 80
})

const notifications = {
  success: ({ message, description }: ArgsProps) =>
    notification.success({
      icon: <Status status={ProcessStatus.SUCCESS} />,
      closeIcon: <CloseCircle />,
      className: '!font-firago !p-4',
      message,
      description
    }),
  error: ({ message, description }: ArgsProps) =>
    notification.error({
      icon: <Status status={ProcessStatus.ERROR} />,
      closeIcon: <CloseCircle />,
      className: '!font-firago !p-4',
      message,
      description
    }),
  warning: ({ message, description }: ArgsProps) =>
    notification.warning({
      icon: <Status status={ProcessStatus.WARNING} />,
      closeIcon: <CloseCircle />,
      className: '!font-firago !p-4',
      message,
      description
    })
}

export default notifications
