import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import uniqid from 'uniqid'
import Link from '@/components/Link'
import useDetectScroll, {
  Axis,
  Direction
} from '@smakss/react-scroll-direction'
import { Category } from '@/types/category'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { useRef, useState } from 'react'

export type HeaderCategoriesProps = {
  className?: string
  categories: Category[]
}

const HeaderCategories = ({ className, categories }: HeaderCategoriesProps) => {
  const [showCategories, setShowCategories] = useState(false)
  const [isCategoriesFixed, setIsCategoriesFixed] = useState(false)
  const { scrollDir, scrollPosition } = useDetectScroll({ axis: Axis.Y })
  const timeout = useRef<any>(null)

  useUpdateEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }

    if (scrollPosition.top < 150) {
      setIsCategoriesFixed(false)
    }

    if (scrollPosition.top > 150 && scrollDir === Direction.Up) {
      setShowCategories(true)
      setIsCategoriesFixed(true)
    } else {
      setShowCategories(false)
    }
  }, [scrollDir, scrollPosition.top])

  return (
    <div className="h-[64px]">
      <nav
        className={classNames(
          'border-b border-gray-300 bg-white font-firago',
          className,
          {
            'fixed z-10 w-full transition-all duration-500': isCategoriesFixed,
            'top-[65px]': showCategories,
            'top-[-69px]': !showCategories
          }
        )}
      >
        <ul className="no-scrollbar flex items-center gap-6 overflow-x-auto p-5 sm:justify-center sm:overflow-x-hidden sm:px-0">
          {categories.map(({ name, slug }) => (
            <li key={uniqid()}>
              <Link href={`/category/${slug}`}>{name}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

export default HeaderCategories
