import { formatBytes } from '@/utils/utils'
import { Rule, RuleObject, RuleRender } from 'antd/es/form'
import useTranslate from './useTranslate'

type UseFormValidation = {
  getServerError: (field: string) => string
  validateIf: (condition: any, rule: Rule) => RuleRender
  rules: {
    required: (props?: any) => Rule
    isStrong: (strength?: string) => Rule
    isEmail: () => Rule
    isAllowedSize: (limit: number) => Rule
    isSame: (field: string) => Rule
    isMobileNumber: () => Rule
    isUserUrl: () => Rule
  }
}

type Props = {
  serverValidationErrors?: any
}

const useFormValidation = (props?: Props): UseFormValidation => {
  const t = useTranslate()
  const { serverValidationErrors } = props ?? {}
  return {
    validateIf:
      (condition, rule) =>
      ({ getFieldValue }) => {
        const validate = condition(getFieldValue)

        if (validate) {
          return rule as RuleObject
        }

        return { pattern: /.*/ }
      },
    rules: {
      required: (options: { type: RuleObject['type'] }) => ({
        required: true,
        message: t('validation.required'),
        type: options?.type
      }),
      isMobileNumber: () => ({
        pattern: /^\d{9}$/,
        message: t('validation.is_phone')
      }),
      isUserUrl: () => ({
        pattern: /^[a-zA-Z0-9][a-zA-Z0-9_-]{2,}$/,
        message: t('validation.is_user_url')
      }),
      isStrong: (strength?: string) => ({
        validator: () =>
          new Promise((resolve, reject) => {
            if (strength === 'weak') {
              reject()
            } else {
              resolve(true)
            }
          }),
        message: t('validation.strong_pwd')
      }),
      isEmail: () => ({
        type: 'email',
        message: t('validation.email')
      }),
      isAllowedSize: (limit: number) => ({
        validator: (_, value: File | File[]) =>
          new Promise((resolve, reject) => {
            if (Array.isArray(value)) {
              const isSomeReached = value.reduce(
                (prev, curr) => prev || curr.size > limit,
                false
              )

              if (isSomeReached) {
                reject()
              } else {
                resolve(true)
              }
            } else {
              if (!!value && value.size > limit) {
                reject()
              } else {
                resolve(true)
              }
            }
          }),
        message: t('validation.file_size_limit_reached', {
          limit: formatBytes(limit)
        })
      }),
      isSame:
        (field) =>
        ({ getFieldValue }) => ({
          validator: (_, value) =>
            new Promise((resolve, reject) => {
              if (!value || getFieldValue(field) === value) {
                resolve(true)
              } else {
                reject()
              }
            }),
          message:
            field === 'password'
              ? t('validation.password_must_match')
              : t('validation.fields_must_match')
        })
    },
    getServerError: (field: string) =>
      serverValidationErrors &&
      serverValidationErrors[field] &&
      serverValidationErrors[field].length &&
      serverValidationErrors[field][serverValidationErrors[field].length - 1]
  }
}

export default useFormValidation
