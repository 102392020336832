import {
  Bank,
  Chart2,
  Heart,
  Location,
  MessageFavorite,
  NotificationBing,
  PasswordCheck,
  Receipt1,
  ReceiptSearch,
  Setting2,
  Share,
  Shop,
  User
} from 'iconsax-react'

type UserProfilePagesOptions = {
  except?: string[]
}
export const getUserProfilePages = (options?: UserProfilePagesOptions) =>
  [
    {
      key: 'profile',
      url: '/profile',
      name: 'common.profile_page',
      icon: <User size="16" />
    },
    {
      key: 'orders',
      url: '/profile/orders',
      name: 'common.orders',
      icon: <Receipt1 size="16" />
    },
    {
      key: 'notifications',
      url: '#',
      name: 'common.notifications',
      icon: (
        <span className="relative">
          <span className="absolute -top-0.5 right-0 z-10 h-2 w-2 rounded-full border border-white bg-red" />
          <NotificationBing size={16} />
        </span>
      )
    },
    {
      key: 'addresses',
      url: '/profile/addresses',
      name: 'common.addresses',
      icon: <Location size="16" />
    },
    {
      key: 'vouchers',
      url: '/profile/vouchers',
      name: 'common.vouchers',
      icon: <PasswordCheck size="16" />
    },
    {
      key: 'favorites',
      url: '/profile/favorites',
      name: 'common.favorites',
      icon: <Heart size="16" />
    },
    {
      key: 'settings',
      url: '/profile/settings',
      name: 'common.settings',
      icon: <Setting2 size="16" />
    }
  ].filter((item) => !options?.except?.includes(item.key))

export const getBrandProfilePages = (brandId: any) => [
  {
    key: 'profile',
    url: `/profile/brand/${brandId}/profile`,
    name: 'common.brand_profile',
    icon: <User size="16" />
  },
  {
    key: 'shop',
    url: `/profile/brand/${brandId}/shop`,
    name: 'common.shop',
    icon: <Shop size="16" />
  },
  {
    key: 'products',
    url: `/profile/brand/${brandId}/products`,
    name: 'common.added_products',
    icon: <ReceiptSearch size="16" />
  },
  {
    key: 'orders',
    url: `/profile/brand/${brandId}/orders`,
    name: 'common.orders_history',
    icon: <Receipt1 size="16" />
  },
  {
    key: 'reviews',
    url: '#',
    name: 'common.reviews',
    icon: <MessageFavorite size="16" />
  },
  {
    key: 'stats',
    url: '#',
    name: 'common.stats',
    icon: <Chart2 size="16" />
  },
  {
    key: 'marketing',
    url: '#',
    name: 'common.marketing',
    icon: <Share size="16" />
  },
  {
    key: 'finances',
    url: '#',
    name: 'common.finance',
    icon: <Bank size="16" />
  },
  {
    key: 'settings',
    url: '#',
    name: 'common.settings',
    icon: <Setting2 size="16" />
  }
]
